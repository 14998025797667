import { useMutation, useQuery } from '@tanstack/react-query'
import { chat } from './chatApi'

export const useChat = () => {
    const { mutate: sendMessage, messagePending } = useMutation({
        mutationFn: ({ prompt, chatID, inboxID }) => chat(prompt, chatID, inboxID),
        onSuccess: (response, variables) => {
            variables?.onSuccess(response)
        },
        onError: (error, variables) => {
            variables?.onError(error)
        },
    })

    return {
        sendMessage,
        messagePending,
    }
}
