import React from 'react'
import { Box } from '@mui/material'

export const ML = () => {
    return (
        <Box sx={{ width: '100%', height: 'calc(100vh - 112px)', padding: '12px' }}>
            BRAD YOU ARE FREE TO WORK HERE
        </Box>
    )
}
