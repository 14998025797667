import Selector from '../../../../common/Selector/Selector'
import { Box, MenuItem } from '@mui/material'
import Typography from '@mui/material/Typography'
import { ScoopColorPicker } from '../../../../common/ScoopColorPicker/ScoopColorPicker'
import React from 'react'
import { CHART_PREFERENCES_DEFAULT_VALUES, DEFAULT_CHART_PREFERENCES } from './styleConsts'

export const renderSelector = (label, value, setValue, options, sx) => {
    return (
        <Selector
            removeMargin
            value={value}
            label={label}
            labelClassName={'selector-label-bold'}
            sx={{ ...sx, height: '38px' }}
        >
            {options.map((option) => (
                <MenuItem
                    value={option.value || option}
                    onClick={() => setValue(option.value || option)}
                >
                    <Typography className={'inter'}>{option.label || option}</Typography>
                </MenuItem>
            ))}
        </Selector>
    )
}

export const renderColorPicker = (label, color, setColor) => {
    return (
        <Box className={'column'} sx={{ gap: '8px' }}>
            {label && <Typography className={'inter style-config-label'}>{label}</Typography>}
            <Box sx={{ padding: '0px 4px' }}>
                <ScoopColorPicker showLabel value={color} onChange={(color) => setColor(color)} />
            </Box>
        </Box>
    )
}

export const renderBoxSelector = (title, selected, onClick) => {
    return (
        <Box
            sx={{
                display: 'flex',
                padding: '6px 8px',
                height: '30px',
                borderRadius: '3px',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: selected && '#E6E4E6',
                outline: !selected && '1px solid #E6E4E6',
                flex: 1,
                cursor: 'pointer',
            }}
            onClick={onClick}
        >
            <Typography
                sx={{
                    fontSize: '14px',
                    fontWeight: '500',
                    fontFamily: 'Inter',
                    color: ' #2B1630',
                }}
            >
                {title}
            </Typography>
        </Box>
    )
}

export const getDefaultChartPreferences = (darkTheme) => {
    const themeString = JSON.stringify({ ...CHART_PREFERENCES_DEFAULT_VALUES })
    if (darkTheme)
        return themeString.replaceAll('#6E7079', '#FFFFFF').replaceAll('#000000FF', '#FFFFFF')
    return themeString
}

export const getEmptyTheme = () => {
    return JSON.parse(JSON.stringify(DEFAULT_CHART_PREFERENCES))
}

export const propExistsOnObject = (override, prop) => {
    return override && override[prop] !== undefined
}
