import React, { useState } from 'react'
import { Box, Typography } from '@mui/material'
import MagicWand from '../../../assets/icons/MagicWand.svg'
import FlowArrow from '../../../assets/icons/FlowArrowWhite.svg'
import TreeStructure from '../../../assets/icons/TreeStructureWhite.svg'
import { Chat } from './Chat/Chat'
import { ML } from './ML/ML'
import { Process } from './Process/Process'

const insightTabs = [
    { label: 'Ask AI', key: 'ask-ai', icon: MagicWand },
    { label: 'Processes', key: 'processes', icon: FlowArrow },
    { label: 'Machine Learning', key: 'machine-learning', icon: TreeStructure },
]

export const Insights = () => {
    const [selectedTab, setSelectedTab] = useState(insightTabs[0].key)

    return (
        <Box className={'screen-container'} sx={{ overflow: 'hidden' }}>
            <Box className={'explorer-nav'}>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    {insightTabs.map((tab, index) => (
                        <Box
                            onClick={() => setSelectedTab(tab.key)}
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                padding: '8px 12px',
                                backgroundColor:
                                    selectedTab === tab.key ? '#5F4466' : 'transparent',
                                opacity: selectedTab === tab.key ? '1' : '0.5',
                                borderRadius: '4px',
                                gap: '8px',
                                '&:hover': { cursor: 'pointer' },
                            }}
                        >
                            <img src={tab.icon} alt={tab.key} />
                            <Typography className={'inter'}>{tab.label}</Typography>
                        </Box>
                    ))}
                </Box>
            </Box>
            <Box className={'explorer-content'}>
                {selectedTab === insightTabs[0].key && <Chat />}
                {selectedTab === insightTabs[1].key && <Process />}
                {selectedTab === insightTabs[2].key && <ML />}
            </Box>
        </Box>
    )
}
