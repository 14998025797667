import React, { useEffect, useRef, useState } from 'react'
import { Box, Typography } from '@mui/material'
import Input from '../../../common/Input/Input'
import Dialog from '../../../common/Dialog/Dialog'
import UserCircleGrey from '../../../../assets/icons/UserCircleGrey.svg'
import { useWorkspaceMetadata } from '../../../../api/hooks/workspaceMetadata/useWorkspaceMetadata'
import { useChat } from '../../../../api/hooks/chat/useChat'
import { useSelector } from 'react-redux'
import { renderSelector } from '../../Explorer/DrawerTabs/Style/utils'
import { useUserInfo } from '../../../../api/hooks/user/useUserInfo'

export const Chat = ({}) => {
    const workspaceID = useSelector((state) => state.auth.workspaceID)
    const userID = useSelector((state) => state.auth.userID)
    const [prompt, setPrompt] = useState('')
    const [messages, setMessages] = useState([])
    const [selectedInbox, setSelectedInbox] = useState('')
    const { user } = useUserInfo()
    const { workspaceMetadata } = useWorkspaceMetadata()
    const { sendMessage, messagePending } = useChat()
    const chatContainer = useRef()

    useEffect(() => {
        if (chatContainer.current) {
            chatContainer.current.scrollTop = chatContainer.current.scrollHeight
        }
    }, [messages.length])

    const handleSendMessage = () => {
        setMessages((prevState) => [...prevState, { prompt }])
        sendMessage({
            prompt,
            chatID: `${userID}-${workspaceID}`,
            inboxID: selectedInbox,
            onSuccess: (data) => {
                setMessages((prevState) => [...prevState, data])
            },
        })
        setPrompt('')
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
                height: 'calc(100vh - 112px)',
                padding: '12px',
            }}
        >
            <Box sx={{ width: '80%', height: '100%' }}>
                {renderSelector(
                    'Select a dataset to ask about',
                    selectedInbox,
                    (inbox) => setSelectedInbox(inbox),
                    workspaceMetadata?.inboxes.map((inbox) => ({
                        label: inbox.label,
                        value: inbox.inboxID,
                    })) || [],
                    { marginBottom: '10px' }
                )}
                <Box sx={{ overflow: 'auto', height: 'calc(100% - 150px)' }} ref={chatContainer}>
                    {messages.length > 0 ? (
                        messages.map((message, index) => {
                            return message.prompt ? (
                                <Box
                                    sx={{
                                        backgroundColor: '#F2F2F2',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        padding: '10px',
                                        borderRadius: '10px',
                                        overflow: 'hidden',
                                    }}
                                >
                                    <img
                                        src={UserCircleGrey}
                                        alt={'UserCircle'}
                                        style={{ height: 40, marginRight: '8px' }}
                                    />
                                    <Typography className={'inter'}>{message.prompt}</Typography>
                                </Box>
                            ) : (
                                <Box sx={{ padding: '10px', overflow: 'hidden' }}>
                                    <Typography className={'inter'}>
                                        {'RESPONSE :' + JSON.stringify(message)}
                                    </Typography>
                                </Box>
                            )
                        })
                    ) : (
                        <Box sx={{ display: 'grid', placeContent: 'center', height: '100%' }}>
                            <Typography className={'inter'}>
                                Hey {user.firstName}, ask a question!
                            </Typography>
                        </Box>
                    )}
                </Box>
                <Input
                    value={prompt}
                    onChange={(e) => setPrompt(e.target.value)}
                    rows={1}
                    placeholder={'Type your question here...'}
                    fullWidth
                    multiline
                    padding={'0'}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter' && !e.shiftKey) {
                            e.preventDefault()
                            handleSendMessage()
                        }
                    }}
                />
            </Box>
        </Box>
    )
}
