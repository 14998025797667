import Dialog from '../../../common/Dialog/Dialog'
import Button from '../../../common/Button/Button'
import Input from '../../../common/Input/Input'
import { Box, Checkbox, Link, Tooltip, Typography } from '@mui/material'
import Selector from '../../../common/Selector/Selector'
import MenuItem from '@mui/material/MenuItem'
import { DataGrid } from '@mui/x-data-grid'
import Grid from '@mui/material/Unstable_Grid2'
import React, { useCallback, useEffect } from 'react'
import { APIFilterField } from './APIFilterField'
import { TreeItem, TreeView } from '@mui/x-tree-view'
import FunctionsIcon from '@mui/icons-material/Functions'
import SquareFootIcon from '@mui/icons-material/SquareFoot'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { ScoopDatePicker } from '../../../common/DatePicker/ScoopDatePicker'
import { displayPorperProductNameInDialog } from './APIUIOverrides'
import { debounce } from 'lodash'
import './APIConnector.css'
import { ScoopLoader } from '../../../common/Spinner/ScoopLoader'

export const APIFields = ({
    dialogOpen,
    APIFeatures,
    stepBack,
    next,
    nextButton,
    onClose,
    fieldList,
    selectedFieldList,
    setSelectedFieldList,
    selectedInbox,
    objectList,
    filterMap,
    setFilterMap,
    reportName,
    selectedObject,
    setReportName,
    handleObjectSelect,
    extractNow,
    setExtractNow,
    startDate,
    setStartDate,
    errorMessage,
    saveLoading,
}) => {
    const [filterField, setFilterField] = React.useState(null)
    const [filterModalOpen, setFilterModalOpen] = React.useState(false)
    const [operator, setOperator] = React.useState('Equals')
    const [numDimensions, setNumDimensions] = React.useState(0)
    const [numMetrics, setNumMetrics] = React.useState(0)
    const [searchText, setSearchText] = React.useState('')
    const [searching, setSearching] = React.useState(false)
    const [filteredFieldList, setFilteredFieldList] = React.useState(fieldList || [])

    useEffect(() => {
        if (fieldList) {
            setFilteredFieldList(fieldList)
        }
    }, [fieldList])

    function onFilterModalClose() {
        setFilterModalOpen(false)
    }

    function renderFilterContent(name) {
        var filter = filterMap.get(name)
        if (filter) {
            let filterString = ''
            switch (filter.operator) {
                case 'Equals':
                    filterString = '=' + filter.values[0]
                    break
                case 'NotEquals':
                    filterString = '!=' + filter.values[0]
                    break
                case 'In':
                    filterString = 'In('
                    for (let i = 0; i < filter.values.length; i++) {
                        if (i > 0) filterString += ','
                        filterString += filter.values[i]
                    }
                    filterString += ')'
                    break
                case 'NotIn':
                    filterString = 'NotIn('
                    for (let i = 0; i < filter.values.length; i++) {
                        if (i > 0) filterString += ','
                        filterString += filter.values[i]
                    }
                    filterString += ')'
                    break
                case 'LastDay':
                    filterString = 'Last Day'
                    break
                case 'LastWeek':
                    filterString = 'Last Week'
                    break
                case 'LastMonth':
                    filterString = 'Last Month'
                    break
                case 'LastYear':
                    filterString = 'Last Year'
                    break
            }
            return (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Link
                        sx={{ width: 40 }}
                        onClick={() => {
                            var newMap = new Map()
                            for (let key in filterMap) {
                                if (key !== name) {
                                    newMap.set(key, filterMap[key])
                                }
                            }
                            setFilterMap(newMap)
                        }}
                    >
                        <Typography>clear</Typography>
                    </Link>
                    <Typography sx={{ width: '100%', ml: 1 }}>{filterString}</Typography>
                </Box>
            )
        } else {
            return ''
        }
    }

    let objectName = 'Object'
    if (APIFeatures) {
        objectName = APIFeatures.objectName ? APIFeatures.objectName : objectName
    }

    function handleCheck(field) {
        let newSelectedFields = []
        let found = false
        for (let i = 0; i < selectedFieldList.length; i++) {
            if (selectedFieldList[i] === field.name) {
                found = true
                if (field.fieldType === 'Dimension') {
                    setNumDimensions(numDimensions - 1)
                } else {
                    setNumMetrics(numMetrics - 1)
                }
            } else {
                newSelectedFields.push(selectedFieldList[i])
            }
        }
        if (!found) {
            if (
                (numDimensions < APIFeatures.maxDimensions && field.fieldType === 'Dimension') ||
                (numMetrics < APIFeatures.maxMetrics && field.fieldType === 'Metric')
            ) {
                if (field.fieldType === 'Dimension') {
                    setNumDimensions(numDimensions + 1)
                } else {
                    setNumMetrics(numMetrics + 1)
                }
                newSelectedFields.push(field.name)
            }
        }
        setSelectedFieldList(newSelectedFields)
    }

    let columns = [
        {
            field: 'value',
            headerName: 'Value',
            type: 'string',
            sortable: false,
            editable: false,
            width: selectedObject && selectedObject.fieldsHaveDescriptions ? 150 : 350,
            headerAlign: 'center',
        },
        {
            field: 'filter',
            headerName: 'Filter',
            type: 'string',
            sortable: false,
            editable: false,
            width: selectedObject && selectedObject.fieldsHaveDescriptions ? 150 : 350,
            headerAlign: 'center',
            renderCell: (params) => {
                if (params.value === 'filter') {
                    if (filterMap.get(params.id)) {
                        return <Box>{renderFilterContent(params.id)}</Box>
                    } else {
                        return (
                            <Link
                                onClick={() => {
                                    for (let i = 0; i < fieldList.length; i++) {
                                        if (fieldList[i].name === params.id) {
                                            setFilterField(fieldList[i])
                                            setFilterModalOpen(true)
                                            if (fieldList[i].fieldType === 'DateTime') {
                                                setOperator('LastDay')
                                            }
                                            break
                                        }
                                    }
                                }}
                            >
                                <Typography>filter</Typography>
                            </Link>
                        )
                    }
                }
            },
        },
    ]

    if (selectedObject && selectedObject.fieldsHaveDescriptions) {
        columns.push({
            field: 'description',
            headerName: 'Description',
            type: 'string',
            sortable: false,
            editable: false,
            width: 700,
            headerAlign: 'left',
        })
    }

    let dimensionCategories = []
    let metricCategories = []
    if (fieldList) {
        fieldList.forEach((field) => {
            if (field.fieldType === 'Dimension') {
                if (!dimensionCategories.includes(field.type)) dimensionCategories.push(field.type)
            }
            if (field.fieldType === 'Metric') {
                if (!metricCategories.includes(field.type)) metricCategories.push(field.type)
            }
        })
    }
    let count = 3

    const debouncedFilter = useCallback(
        debounce((value) => {
            setFilteredFieldList(
                fieldList.filter((field) => field.value.toLowerCase().includes(value.toLowerCase()))
            )
            setTimeout(() => setSearching(false), 100)
        }, 300),
        [fieldList]
    )

    const handleSearchChange = (event) => {
        const value = event.target.value
        setSearchText(value)
        setSearching(true)
        debouncedFilter(value)
    }

    return (
        <>
            <Dialog
                open={dialogOpen}
                title={
                    'Dataset from ' +
                    displayPorperProductNameInDialog(APIFeatures?.connectorName) +
                    ' ' +
                    objectName
                }
                onClose={onClose}
                actions={
                    <>
                        <Button className={'button-grey small'} onClick={stepBack}>
                            Back
                        </Button>
                        <Button
                            className={'button-purple small'}
                            disabled={
                                saveLoading ||
                                reportName.trim().length === 0 ||
                                (selectedFieldList.length === 0 && APIFeatures.canSelectFields)
                            }
                            onClick={() => {
                                next()
                            }}
                        >
                            {saveLoading ? <ScoopLoader size={20} /> : nextButton}
                        </Button>
                    </>
                }
                maxWidth={1000}
                sx={{ p: 2 }}
            >
                <Input
                    sx={{ width: '100%' }}
                    label={'Dataset name'}
                    onChange={(event) => {
                        setReportName(event.target.value)
                    }}
                    disabled={selectedInbox && selectedInbox.extractDefinition}
                    value={reportName}
                />
                <Box
                    sx={{
                        padding: '12px 16px',
                        fontSize: '14px',
                        background: '#F9F9F9',
                        borderRadius: '5px',
                        color: '#635566',
                    }}
                >
                    <Selector
                        sx={{ width: '100%', height: 35 }}
                        label={'Choose ' + objectName + ' to retrieve'}
                        value={selectedObject ? selectedObject.name : null}
                        disabled={selectedInbox && selectedInbox.extractDefinition}
                    >
                        {objectList.map((object) => (
                            <MenuItem
                                value={object.name}
                                key={object.name}
                                onClick={() => {
                                    handleObjectSelect(object, APIFeatures)
                                }}
                            >
                                {object.label}
                            </MenuItem>
                        ))}
                    </Selector>
                    {errorMessage && <Typography sx={{ color: 'red' }}>{errorMessage}</Typography>}
                    {fieldList && fieldList.length > 0 && !APIFeatures?.isDimensional && (
                        <Box>
                            {' '}
                            {APIFeatures?.canSelectFields && (
                                <Box sx={{ marginTop: '16px' }}>
                                    <Typography>Search fields</Typography>
                                    <Input
                                        value={searchText}
                                        onChange={handleSearchChange}
                                        type={'text'}
                                    />
                                    <Typography sx={{ mt: 2 }}>
                                        Select the fields you would like to retrieve
                                    </Typography>
                                    <Box sx={{ display: 'flex', gap: '8px', mb: '10px' }}>
                                        <Button
                                            className={'button-grey fields-button'}
                                            onClick={() =>
                                                setSelectedFieldList(fieldList.map((f) => f.id))
                                            }
                                        >
                                            Select all
                                        </Button>
                                        <Button
                                            className={'button-grey fields-button'}
                                            onClick={() => setSelectedFieldList([])}
                                        >
                                            Clear selection
                                        </Button>
                                    </Box>
                                </Box>
                            )}
                            {!APIFeatures?.canSelectFields && (
                                <Typography sx={{ mt: 2 }}>Fields found</Typography>
                            )}
                            <DataGrid
                                autoHeight
                                disableRowSelectionOnClick={true}
                                columns={columns}
                                pageSizeOptions={[15]}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            page: 0,
                                            pageSize: 15,
                                        },
                                    },
                                }}
                                rows={filteredFieldList}
                                rowHeight={25}
                                checkboxSelection={APIFeatures.canSelectFields}
                                rowSelectionModel={
                                    APIFeatures.canSelectFields ? selectedFieldList : []
                                }
                                onRowSelectionModelChange={(ids) => {
                                    if (!searching && ids.length) setSelectedFieldList(ids)
                                }}
                                sx={{
                                    '& .Mui-checked': {
                                        color: '#E50B54',
                                    },
                                    '& .MuiDataGrid-columnHeaders': {
                                        display: 'none',
                                    },
                                }}
                            />
                        </Box>
                    )}
                    {fieldList && APIFeatures?.isDimensional && (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                height: '470px',
                                mt: 2,
                            }}
                        >
                            <TreeView
                                sx={{
                                    flex: 1,
                                    mb: 1,
                                    width: 900,
                                    textAlign: 'left',
                                    overflow: 'auto',
                                    padding: '8px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    '& .MuiTreeItem-content.Mui-selected': {
                                        backgroundColor: 'transparent !important',
                                    },
                                    '& .MuiTreeItem-content': {
                                        ':hover': {
                                            backgroundColor: 'transparent !important',
                                        },
                                    },
                                    '& .MuiTreeItem-label': {
                                        ':hover': {
                                            backgroundColor: 'transparent !important',
                                        },
                                    },
                                }}
                                defaultExpanded={[1, 2]}
                                defaultCollapseIcon={<ExpandMoreIcon />}
                                defaultExpandIcon={<ChevronRightIcon />}
                            >
                                <TreeItem icon={<SquareFootIcon />} label={'Dimensions'} nodeId={1}>
                                    {dimensionCategories.map((dimension) => (
                                        <TreeItem
                                            nodeId={'id' + count++}
                                            label={dimension}
                                            sx={{
                                                padding: '0px',
                                            }}
                                        >
                                            {fieldList
                                                .filter(
                                                    (field) =>
                                                        field.fieldType === 'Dimension' &&
                                                        field.type === dimension
                                                )
                                                .map((field) => (
                                                    <TreeItem
                                                        sx={{ height: 22 }}
                                                        nodeId={'id' + count++}
                                                        label={
                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    fontSize: 14,
                                                                    height: 22,
                                                                }}
                                                            >
                                                                <Checkbox
                                                                    checked={selectedFieldList.includes(
                                                                        field.name
                                                                    )}
                                                                    onClick={() => {
                                                                        handleCheck(field)
                                                                    }}
                                                                    sx={{
                                                                        padding: '0px',
                                                                        '&.Mui-checked': {
                                                                            color: '#E50B54',
                                                                        },
                                                                        color: '#E6E4E6',
                                                                    }}
                                                                />
                                                                <Tooltip
                                                                    title={field.description}
                                                                    arrow
                                                                >
                                                                    <Typography
                                                                        sx={{
                                                                            fontSize: 14,
                                                                            ml: 1,
                                                                            flexGrow: 1,
                                                                            overflow: 'hidden',
                                                                            textOverflow:
                                                                                'ellipsis',
                                                                            whiteSpace: 'nowrap',
                                                                        }}
                                                                    >
                                                                        {field.label}
                                                                        {field.description
                                                                            ? ': ' +
                                                                              field.description
                                                                            : ''}
                                                                    </Typography>
                                                                </Tooltip>
                                                            </Box>
                                                        }
                                                    ></TreeItem>
                                                ))}
                                        </TreeItem>
                                    ))}
                                </TreeItem>
                                <TreeItem
                                    icon={<FunctionsIcon />}
                                    label={'Metrics'}
                                    nodeId={'id' + 2}
                                >
                                    {metricCategories.map((metric) => (
                                        <TreeItem nodeId={'id' + count++} label={metric}>
                                            {fieldList
                                                .filter(
                                                    (field) =>
                                                        field.fieldType === 'Metric' &&
                                                        field.type === metric
                                                )
                                                .map((field) => (
                                                    <TreeItem
                                                        sx={{ height: 22 }}
                                                        nodeId={'id' + count++}
                                                        label={
                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    fontSize: 14,
                                                                    height: 22,
                                                                }}
                                                            >
                                                                <Checkbox
                                                                    checked={selectedFieldList.includes(
                                                                        field.name
                                                                    )}
                                                                    onClick={() => {
                                                                        handleCheck(field)
                                                                    }}
                                                                    sx={{
                                                                        '&.Mui-checked': {
                                                                            color: '#E50B54',
                                                                        },
                                                                        color: '#E6E4E6',
                                                                        padding: '0px',
                                                                    }}
                                                                />
                                                                <Tooltip
                                                                    title={field.description}
                                                                    arrow
                                                                >
                                                                    <Typography
                                                                        sx={{
                                                                            fontSize: 14,
                                                                            ml: 1,
                                                                            flexGrow: 1,
                                                                            overflow: 'hidden',
                                                                            textOverflow:
                                                                                'ellipsis',
                                                                            whiteSpace: 'nowrap',
                                                                        }}
                                                                    >
                                                                        {field.label}
                                                                        {field.description
                                                                            ? ': ' +
                                                                              field.description
                                                                            : ''}
                                                                    </Typography>
                                                                </Tooltip>
                                                            </Box>
                                                        }
                                                    ></TreeItem>
                                                ))}
                                        </TreeItem>
                                    ))}
                                </TreeItem>
                            </TreeView>
                        </Box>
                    )}
                </Box>
                {APIFeatures?.requireDateRange && (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '10px',
                            alignItems: 'center',
                            width: '100%',
                        }}
                    >
                        <Typography>Start Date:</Typography>
                        <ScoopDatePicker
                            value={startDate}
                            containerSx={{ width: '150px' }}
                            range={false}
                            onChange={(newDate) => {
                                setStartDate(newDate)
                            }}
                        ></ScoopDatePicker>
                    </Box>
                )}
                <Grid container spacing={2} sx={{ mt: -2, mb: 0, width: '100%' }}>
                    <Grid xs={0.5}>
                        <Checkbox
                            sx={{
                                padding: 0,
                                '&.Mui-checked': { color: '#E50B54' },
                                color: '#E6E4E6',
                            }}
                            checked={extractNow}
                            onChange={() => {
                                setExtractNow(!extractNow)
                            }}
                        />
                    </Grid>
                    <Grid xs={11}>
                        <Typography sx={{ ml: 1 }}>Extract data now</Typography>
                    </Grid>
                </Grid>
            </Dialog>
            {filterField && (
                <APIFilterField
                    APIFeatures={APIFeatures}
                    filterField={filterField}
                    filterModalOpen={filterModalOpen}
                    onFilterModalClose={onFilterModalClose}
                    setFilterField={setFilterField}
                    setFilterModalOpen={setFilterModalOpen}
                    filterMap={filterMap}
                    operator={operator}
                    setOperator={setOperator}
                ></APIFilterField>
            )}
        </>
    )
}
